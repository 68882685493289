import { Link } from "react-router-dom"
import { useKasware } from "../../../hooks/useKasware"
import { getAddressRoute } from "../../../hooks/navigator"
import { ConnectWalletButton } from "./ConnectWalletButton"
import { useIntl } from "react-intl"
export const SwapSettingsModalBody = ({
  slippage,
  setSlippage,
}: {
  slippage: number
  setSlippage: (value: number) => void
}) => {
  const intl = useIntl()
  const { connected, disconnectWallet, address } = useKasware()

  return (
    <>
      <div className="mb-8">
        <div className="mb-4">
          <div className="fs-3 fw-semibold mb-2">
            {intl.formatMessage({ id: "MAX_SLIPPAGE" })}
          </div>
          <div className="d-flex gap-2">
            {[0.5, 1.0, 2.0, 3.0, 5.0].map((value) => (
              <button
                key={value}
                onClick={() => setSlippage(value)}
                className={`btn btn-sm ${
                  slippage === value ? "btn-primary" : "btn-secondary"
                }`}
              >
                {value}%
              </button>
            ))}
            <div className="input-group input-group-sm">
              <input
                type="number"
                className="form-control"
                value={slippage}
                onChange={(e) => setSlippage(Number(e.target.value))}
                placeholder="Custom"
              />
              <span className="input-group-text">%</span>
            </div>
          </div>
        </div>

        <div>
          <div className="mb-1 fs-3 fw-semibold">
            {intl.formatMessage({ id: "CONNECT_KASWARE" })}
          </div>
          <div className="d-flex">
            <div className="text-dark">
              {connected ? (
                <Link to={getAddressRoute(address)}>{address}</Link>
              ) : (
                <ConnectWalletButton />
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="">
        {connected && (
          <button
            className="btn btn-danger w-100"
            onClick={() =>
              disconnectWallet && disconnectWallet(window.location.origin)
            }
          >
            {intl.formatMessage({ id: "DISCONNECT_WALLET" })}
          </button>
        )}
      </div>
    </>
  )
}
