import { Route, Routes, Navigate } from "react-router-dom"
import { MasterLayout } from "../../_metronic/layout/MasterLayout"
import { DashboardWrapper } from "../pages/dashboard/DashboardWrapper"
import { TransactionWrapper } from "../pages/transaction/Transaction"
import { BlockWrapper } from "../pages/block/Block"
import { AddressWrapper } from "../pages/address/Address"
import { HoldersWrapper } from "../pages/holders/HoldersWrapper"
import { DeflationaryTableSection } from "../pages/tools/DeflationaryTableSection"
import { DonorsWrapper } from "../pages/donors"
import { TokenWrapper } from "../pages/token/Token"
import { Krc20TokenDashboard } from "../pages/token-dashboard/Krc20TokenDashboard"
import { TosWrapper } from "../pages/tos"

const PrivateRoutes = () => {
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Pages */}
        <Route path="" element={<DashboardWrapper />} />
        <Route path="transaction/:hash" element={<TransactionWrapper />} />
        <Route path="block/:hash" element={<BlockWrapper />} />
        <Route path="address/:hash" element={<AddressWrapper />} />
        <Route path="top-addresses" element={<HoldersWrapper />} />
        <Route path="krc20-tokens" element={<Krc20TokenDashboard />} />
        <Route
          path="tools/emission-schedule"
          element={<DeflationaryTableSection />}
        />
        <Route path="donors" element={<DonorsWrapper />} />
        <Route path="tos" element={<TosWrapper />} />
        <Route path="token/krc20/:ticker" element={<TokenWrapper />} />

        {/* Redirects */}
        <Route path="txs/:hash" element={<TransactionWrapper />} />
        <Route path="blocks/:hash" element={<BlockWrapper />} />
        <Route path="addresses/:hash" element={<AddressWrapper />} />

        {/* Deprecated routes: just do redirect */}
        <Route path="holders" element={<Navigate to="/top-addresses" />} />

        {/* Page Not Found */}
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  )
}

export { PrivateRoutes }
