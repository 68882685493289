import { useEffect, useRef, useState } from "react"
import { useIntl } from "react-intl"
import { createChart, CandlestickData } from "lightweight-charts"
import { Card } from "../../../components/Card"
import { ChartIntervalSegment } from "./ChartIntervalSegment"
import { TokenCandle } from "../../../../client"
import { useThemeMode } from "../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider"
import { ChartTooltip } from "./ChartTooltip"
import { useGlobalData } from "../../../utils/GlobalDataProvider"
import { Krc20PriceUnitToggleButton } from "../../../components/partials/Krc20PriceUnitToggleButton"
import { Spin } from "antd"
import moment from "moment"
import { ChartInterval } from "../Token"

const TokenPriceChart = ({
  candles = [],
  chartInterval,
  onIntervalChange,
  loading,
  ticker,
}: {
  candles?: TokenCandle[]
  chartInterval: ChartInterval
  onIntervalChange: (interval: ChartInterval) => void
  loading?: boolean
  ticker: string
}) => {
  const intl = useIntl()
  const chartContainerRef = useRef<HTMLDivElement>(null)
  const { mode } = useThemeMode()
  const isDarkMode = mode === "dark"
  const [tooltipData, setTooltipData] = useState<CandlestickData | null>(null)
  const [showTooltip, setShowTooltip] = useState(false)
  const { krc20PriceUnit } = useGlobalData()

  useEffect(() => {
    if (!chartContainerRef.current) return

    const chart = createChart(chartContainerRef.current, {
      layout: {
        background: { color: isDarkMode ? "#151521" : "#ffffff" },
        textColor: isDarkMode ? "#92929F" : "#333",
      },
      grid: {
        vertLines: { color: isDarkMode ? "#2B2B43" : "#f0f0f0" },
        horzLines: { color: isDarkMode ? "#2B2B43" : "#f0f0f0" },
      },
      width: chartContainerRef.current.clientWidth,
      height: 500,
      timeScale: {
        timeVisible: true,
        secondsVisible: false,
        tickMarkFormatter: (time: number) => {
          const format = chartInterval === "1d" ? "HH:mm" : "MM/DD"
          return moment(time * 1000).format(format)
        },
        rightOffset: 5,
        barSpacing: 20,
        fixLeftEdge: true,
        fixRightEdge: true,
        lockVisibleTimeRangeOnResize: true,
      },
      crosshair: {
        vertLine: {
          visible: true,
          labelVisible: false,
        },
        horzLine: {
          visible: true,
          labelVisible: true,
        },
      },
    })

    // Format the data for the candlestick series
    const formattedData = candles
      .map((item) => ({
        time: moment(item.timestamp).unix(),
        open: Number(krc20PriceUnit === "KAS" ? item.open_kas : item.open),
        high: Number(krc20PriceUnit === "KAS" ? item.high_kas : item.high),
        low: Number(krc20PriceUnit === "KAS" ? item.low_kas : item.low),
        close: Number(krc20PriceUnit === "KAS" ? item.close_kas : item.close),
      }))
      .sort((a, b) => a.time - b.time)

    const candlestickSeries = chart.addCandlestickSeries({
      upColor: "#26a69a",
      downColor: "#ef5350",
      borderVisible: false,
      wickUpColor: "#26a69a",
      wickDownColor: "#ef5350",
      priceFormat: {
        type: "price",
        precision: 8,
        minMove: 0.00000001,
      },
    })

    if (formattedData.length > 0) {
      candlestickSeries.setData(formattedData as CandlestickData[])
    }

    chart.subscribeCrosshairMove((param) => {
      if (
        param.time === undefined ||
        !param.point ||
        param.point.x < 0 ||
        param.point.x > chartContainerRef.current!.clientWidth ||
        param.point.y < 0 ||
        param.point.y > chartContainerRef.current!.clientHeight
      ) {
        setShowTooltip(false)
        return
      }

      const data = param.seriesData.get(candlestickSeries) as CandlestickData
      if (!data) {
        setShowTooltip(false)
        return
      }

      setTooltipData(data)
      setShowTooltip(true)
    })

    // Fit the chart to the data
    chart.timeScale().fitContent()

    // Cleanup
    return () => {
      chart.remove()
    }
  }, [chartInterval, isDarkMode, krc20PriceUnit, candles])

  return (
    <div className="col-xl-12">
      <Card
        title={
          <div className="d-flex align-items-center gap-2">
            {intl.formatMessage({
              id: "TOKEN.HEADER.PRICE_CHART",
            })}
            <span className="text-muted">
              ({ticker} / {krc20PriceUnit})
            </span>
            <span>
              <Krc20PriceUnitToggleButton />
            </span>
          </div>
        }
        toolbar={
          <ChartIntervalSegment
            chartInterval={chartInterval}
            onIntervalChange={onIntervalChange}
          />
        }
        body={
          <Spin spinning={loading} size="large">
            <div ref={chartContainerRef} style={{ position: "relative" }}>
              {showTooltip && tooltipData && (
                <ChartTooltip
                  open={tooltipData.open}
                  high={tooltipData.high}
                  low={tooltipData.low}
                  close={tooltipData.close}
                  change={
                    ((tooltipData.close - tooltipData.open) /
                      tooltipData.open) *
                    100
                  }
                  timestamp={Number(tooltipData.time)}
                  isDarkMode={isDarkMode}
                  unit={krc20PriceUnit || ""}
                />
              )}
            </div>
          </Spin>
        }
      />
    </div>
  )
}

export { TokenPriceChart }
