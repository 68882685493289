import {
  ChaingeAsset,
  GetSwapQuoteResponse,
  SwapOrder,
} from "../../../../client"

export enum SwapStatus {
  NEW = "new",
  QUOTED = "quoted",
  PAID = "paid",
  SIGNED = "signed",
  SUBMITTED = "submitted",
  COMPLETED = "completed",
  DROPPED = "dropped",
  REFUNDED = "refunded",
}

export class Swap {
  id: string = crypto.randomUUID()
  createdAt: number = Date.now()
  swapStatus: SwapStatus = SwapStatus.NEW
  fromAmount: number | undefined
  fromAsset: ChaingeAsset | undefined
  toAsset: ChaingeAsset | undefined

  // QUOTED
  quoteResponse: GetSwapQuoteResponse | undefined

  // PAID
  txnId: string | undefined
  slippage: number | undefined

  // SIGNED
  signature: string | undefined

  // SUBMITTED
  orderId: string | undefined
  submittedAt: number | undefined

  // COMPLETED
  swapOrder: SwapOrder | undefined
  completedAt: number | undefined

  onSwapQuoted({
    quoteResponse,
    fromAmount,
    fromAsset,
    toAsset,
  }: {
    quoteResponse: GetSwapQuoteResponse
    fromAmount: number
    fromAsset: ChaingeAsset
    toAsset: ChaingeAsset
  }) {
    if (this.swapStatus !== SwapStatus.NEW) {
      throw new Error("Invalid swap state")
    }
    this.createdAt = Date.now()
    this.swapStatus = SwapStatus.QUOTED
    this.quoteResponse = quoteResponse
    this.fromAmount = fromAmount
    this.fromAsset = fromAsset
    this.toAsset = toAsset
  }

  onSwapPaid(txnId: string, slippage: number) {
    if (this.swapStatus !== SwapStatus.QUOTED) {
      throw new Error("Invalid swap state during onSwapPaid")
    }
    this.swapStatus = SwapStatus.PAID
    this.txnId = txnId
    this.slippage = slippage
  }

  onSwapSigned(signature: string) {
    if (this.swapStatus !== SwapStatus.PAID) {
      throw new Error("Invalid swap state during onSwapSigned")
    }
    this.swapStatus = SwapStatus.SIGNED
    this.signature = signature
  }

  onSwapSubmitted(orderId: string) {
    if (this.swapStatus !== SwapStatus.SIGNED) {
      throw new Error("Invalid swap state during onSwapSubmitted")
    }
    this.swapStatus = SwapStatus.SUBMITTED
    this.orderId = orderId
    this.submittedAt = Date.now()
  }

  onSwapCompleted(swapOrder: SwapOrder) {
    this.swapStatus = SwapStatus.COMPLETED
    this.swapOrder = swapOrder
    this.completedAt = Date.now()
  }

  onSwapDropped() {
    if (this.swapStatus !== SwapStatus.SUBMITTED) {
      throw new Error("Invalid swap state during onSwapDropped")
    }
    this.swapStatus = SwapStatus.DROPPED
  }

  onSwapRefunded() {
    if (this.swapStatus !== SwapStatus.SUBMITTED) {
      throw new Error("Invalid swap state during onSwapRefunded")
    }
    this.swapStatus = SwapStatus.REFUNDED
  }
}
