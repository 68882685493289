import BigNumber from "bignumber.js"
import { KaswareKrc20Balance } from "../../hooks/useKasware"
import { hexlify, toUtf8Bytes } from "ethers"

import { Swap } from "./services/models"
import { SwapApiService } from "../../../client"
import { PendingStep } from "./components/SwapCard"
import { kaspaToSompi } from "../../utils/utils"
import { KAS_DECIMAL } from "../../constants/constants"
import {
  SignablePayload,
  AggregatedQuote,
  SourceCert,
  ChaingeAsset,
} from "../../../client"
import { parseUnits } from "ethers"
import { formatUnits } from "ethers"
import { IntlShape } from "react-intl"

export const getBalanceForToken = (
  balances: KaswareKrc20Balance[],
  ticker: string
): BigNumber => {
  return BigNumber(
    balances?.filter((b) => b.tick === ticker)?.[0]?.balance || 0
  )
}

interface PerformSwapParams {
  intl: IntlShape
  quoteResponse: any
  amount: number
  slippage: number
  address: string
  publicKey: string
  sendKaspa: (receiver: string, amount: number) => Promise<string>
  signKRC20Transaction: (
    json: string,
    transferType: number,
    receiver: string
  ) => Promise<string>
  signMessage: (message: string) => Promise<string>
  updateSwap: (swap: Swap) => void
  setPendingStep: (step: PendingStep | undefined) => void
}

export const getSignableMessageForSwap = ({
  quote,
  signablePayload,
  sourceCerts,
  toAsset,
}: {
  quote: AggregatedQuote
  signablePayload: Omit<SignablePayload, "sourceCerts"> & {
    sourceCerts: string
  }
  sourceCerts: SourceCert
  toAsset: ChaingeAsset
}) => {
  const expectedReceiveWhole = formatUnits(
    quote.expectedReceiveAmount,
    quote.chainDecimal
  ) // For UI display

  const roundedExpectedReceiveWhole = new BigNumber(
    expectedReceiveWhole
  ).toFixed(toAsset.decimals, BigNumber.ROUND_DOWN) // round down to avoid rounding error

  const expectedReceiveForExtra = parseUnits(
    roundedExpectedReceiveWhole,
    toAsset.decimals
  ).toString() // For signing

  return `${sourceCerts.certHash}_${sourceCerts.fromChain}_${sourceCerts.fromIndex}_${sourceCerts.fromAmount}_${signablePayload.toChain}_${signablePayload.toIndex}_${expectedReceiveForExtra}_${signablePayload.toAddr}`
}

export const performSwap = async ({
  intl,
  quoteResponse,
  amount,
  slippage,
  address,
  publicKey,
  sendKaspa,
  signKRC20Transaction,
  signMessage,
  updateSwap,
  setPendingStep,
}: PerformSwapParams): Promise<{
  success: boolean
  error?: string
  orderId?: string
}> => {
  try {
    if (!quoteResponse)
      return {
        success: false,
        error: intl.formatMessage({ id: "QUOTE_NOT_AVAILABLE" }),
      }
    const { paymentInstruction, signablePayload, quote, fromAsset, toAsset } =
      quoteResponse

    setPendingStep(PendingStep.PAY)

    const swap = new Swap()
    swap.onSwapQuoted({
      quoteResponse,
      fromAmount: amount,
      fromAsset,
      toAsset,
    })
    updateSwap(swap)

    const { receiver, sendAmount, paymentType, ticker } = paymentInstruction
    let txId
    if (paymentType === "KAS") {
      const tx = await sendKaspa(receiver, Number(sendAmount)).catch((e) => {
        throw new Error(
          intl.formatMessage(
            { id: "FAILED_TO_SEND_KAS_REASON" },
            { reason: e.message }
          )
        )
      })
      txId = JSON.parse(tx).id
    } else {
      const transferType = 4
      const json = `{"p":"KRC-20","op":"transfer","tick":"${ticker}","amt":"${sendAmount}","to":"${receiver}"}`
      const tx = await signKRC20Transaction(json, transferType, receiver).catch(
        (e) => {
          throw new Error(
            intl.formatMessage(
              { id: "FAILED_TO_SEND_KRC20_REASON" },
              { reason: e.message, ticker }
            )
          )
        }
      )
      txId = JSON.parse(tx).revealId
    }

    swap.onSwapPaid(txId, slippage)
    updateSwap(swap)

    const sourceCerts = {
      ...signablePayload.sourceCerts,
      fromAddr: address,
      certHash: txId,
      fromPublicKey: publicKey,
    } as SourceCert
    const sourceCertsStr = JSON.stringify(sourceCerts)
    const sourceCertsHex = hexlify(toUtf8Bytes(sourceCertsStr)).substring(2)

    const signablePayloadWithSourceCerts = {
      ...signablePayload,
      sourceCerts: sourceCertsHex,
      toAddr: address,
      slippage: BigNumber(slippage).multipliedBy(BigNumber(100)).toFixed(0),
    }

    setPendingStep(PendingStep.SIGN)
    const signableMessage = getSignableMessageForSwap({
      quote,
      signablePayload: signablePayloadWithSourceCerts,
      sourceCerts,
      toAsset,
    })
    const signature = await signMessage(signableMessage).catch((e) => {
      throw new Error(
        intl.formatMessage(
          { id: "FAILED_TO_SIGN_MESSAGE_REASON" },
          { reason: e.message }
        )
      )
    })

    swap.onSwapSigned(signature)
    updateSwap(swap)

    const orderResponse = await SwapApiService.swapControllerSubmitOrder({
      address,
      publicKey,
      signature,
      signedPayload: signablePayloadWithSourceCerts,
      certHash: txId,
    })

    swap.onSwapSubmitted(orderResponse.orderId)
    updateSwap(swap)

    return { success: true, orderId: orderResponse.orderId }
  } catch (e: any) {
    return { success: false, error: e.message }
  }
}

export const RESERVED_KAS_FOR_GAS = 5
export const hasSufficientKasForGas = (
  balance: BigNumber,
  swapAmount: number
) => {
  const reservedKasForGasBase = kaspaToSompi(RESERVED_KAS_FOR_GAS)
  const swapAmountBase = BigNumber(swapAmount).times(10 ** KAS_DECIMAL)

  return balance.minus(swapAmountBase).minus(reservedKasForGasBase).gte(0)
}
