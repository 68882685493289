/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetKrc20StatusResponse } from '../models/GetKrc20StatusResponse';
import type { GetKrc20TokenListResponse } from '../models/GetKrc20TokenListResponse';
import type { GetKrc20TrendingResponse } from '../models/GetKrc20TrendingResponse';
import type { GetTokenChartsDataResponse } from '../models/GetTokenChartsDataResponse';
import type { GetTokenSummaryResponse } from '../models/GetTokenSummaryResponse';
import type { GetTokenTransactionsResponse } from '../models/GetTokenTransactionsResponse';
import type { PostTokenInfoDto } from '../models/PostTokenInfoDto';
import type { String } from '../models/String';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TokenService {

    /**
     * Return Kaspa KRC20 token trending list
     * @returns GetKrc20TrendingResponse
     * @throws ApiError
     */
    public static tokenControllerGetKrc20Trending(): CancelablePromise<GetKrc20TrendingResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/token/krc20/trending',
        });
    }

    /**
     * Return Kaspa KRC20 token network status
     * @returns GetKrc20StatusResponse
     * @throws ApiError
     */
    public static tokenControllerGetKrc20Status(): CancelablePromise<GetKrc20StatusResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/token/krc20/stats',
        });
    }

    /**
     * Return Kaspa KRC20 token list
     * @returns GetKrc20TokenListResponse
     * @throws ApiError
     */
    public static tokenControllerGetKrc20TokenList(): CancelablePromise<GetKrc20TokenListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/token/krc20/tokens',
        });
    }

    /**
     * Return summary of a Kaspa KRC20 token
     * @param ticker A Kaspa KRC20 ticker
     * @returns GetTokenSummaryResponse
     * @throws ApiError
     */
    public static tokenControllerGetTokenSummary(
        ticker: string,
    ): CancelablePromise<GetTokenSummaryResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/token/krc20/{ticker}/info',
            path: {
                'ticker': ticker,
            },
        });
    }

    /**
     * @param requestBody
     * @returns String
     * @throws ApiError
     */
    public static tokenControllerPostTokenInfo(
        requestBody: PostTokenInfoDto,
    ): CancelablePromise<String> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/token/krc20/{ticker}/info',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Return charts data for Kaspa KRC20 tokens
     * @param ticker A Kaspa KRC20 ticker
     * @param type Type of the charts data. "candles" for price history, "stats" for other stats like transfer/holders/minting counts
     * @param interval Interval for the charts data
     * @returns GetTokenChartsDataResponse
     * @throws ApiError
     */
    public static tokenControllerGetTokenChartsData(
        ticker: string,
        type: 'candles' | 'stats',
        interval: '1d' | '7d' | '1m' | '1y',
    ): CancelablePromise<GetTokenChartsDataResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/token/krc20/{ticker}/charts',
            path: {
                'ticker': ticker,
            },
            query: {
                'type': type,
                'interval': interval,
            },
        });
    }

    /**
     * Return list of transactions from Kaspa KRC20 tokens
     * @param nextCursor Start from this cursor
     * @param address Filter by address
     * @param ticker A Kaspa KRC20 token ticker
     * @returns GetTokenTransactionsResponse
     * @throws ApiError
     */
    public static tokenControllerGetTokenTransactions(
        nextCursor?: string,
        address?: string,
        ticker?: string,
    ): CancelablePromise<GetTokenTransactionsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/token/krc20/transactions',
            query: {
                'nextCursor': nextCursor,
                'address': address,
                'ticker': ticker,
            },
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static tokenControllerPostTokenTransactions(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/token/krc20/transactions/chainge',
        });
    }

    /**
     * Get backend health
     * @returns any
     * @throws ApiError
     */
    public static tokenControllerGetHealth(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/token/health',
        });
    }

}
