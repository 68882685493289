import moment from "moment"
import { FC } from "react"

interface ChartTooltipProps {
  open: number
  high: number
  low: number
  close: number
  change: number
  isDarkMode: boolean
  unit: string
  timestamp: number
}

export const ChartTooltip: FC<ChartTooltipProps> = ({
  open,
  high,
  low,
  close,
  change,
  isDarkMode,
  unit,
  timestamp,
}) => {
  const color = change >= 0 ? "#26a69a" : "#ef5350"

  return (
    <div
      style={{
        position: "absolute",
        zIndex: 2,
        top: "12px",
        left: "12px",
        pointerEvents: "none",
        backgroundColor: isDarkMode ? "#1E1E2D" : "#ffffff",
        border: `1px solid ${isDarkMode ? "#2B2B43" : "#f0f0f0"}`,
        padding: "8px 12px",
        borderRadius: "4px",
        fontSize: "12px",
        color: isDarkMode ? "#92929F" : "#333",
        boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "4px",
          gap: "4px",
        }}
      >
        <span>
          O: {open.toFixed(8)} {unit}
        </span>
        <span>
          H: {high.toFixed(8)} {unit}
        </span>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "4px",
          gap: "4px",
        }}
      >
        <span>
          L: {low.toFixed(8)} {unit}
        </span>
        <span>
          C: {close.toFixed(8)} {unit}
        </span>
      </div>
      <div
        style={{
          display: "flex",
          borderTop: `1px solid ${isDarkMode ? "#2B2B43" : "#f0f0f0"}`,
          paddingTop: "4px",
          marginTop: "4px",
          gap: 8,
        }}
      >
        <span>
          {close.toFixed(8)} {unit}
        </span>
        <span style={{ color }}>
          {change >= 0 ? "+" : ""}
          {change.toFixed(2)}%
        </span>
      </div>
      <div
        style={{
          display: "flex",
          paddingTop: "4px",
          color: isDarkMode ? "#92929F" : "#333",
        }}
      >
        <span>{moment(timestamp * 1000).format("YYYY-MM-DD HH:mm:ss")}</span>
      </div>
    </div>
  )
}
