import { useEffect, useState } from "react"
import { toAbsoluteUrl } from "../../../_metronic/helpers"
import { useThemeMode } from "../../../_metronic/partials/layout/theme-mode/ThemeModeProvider"
import { Popover } from "antd"
import { Image } from "antd"

export const TokenIcon = ({
  url,
  className,
  size = "20px",
  allowPopover = true,
}: {
  url?: string
  className?: string
  size?: string
  allowPopover?: boolean
}) => {
  const [imageError, setImageError] = useState(false)
  const { mode } = useThemeMode()

  const handleImageError = () => {
    setImageError(true)
  }

  const placeholderImage = toAbsoluteUrl(
    mode === "dark"
      ? "/media/svg/files/blank-image-dark.svg"
      : "/media/svg/files/blank-image.svg"
  )

  const imageUrl = imageError ? placeholderImage : url

  useEffect(() => {
    setImageError(false)
  }, [url])

  const PopoverContent = (
    <Image width={300} height={300} preview={false} src={imageUrl} alt={url} />
  )

  const Logo = (
    <div className={`symbol symbol-circle symbol-${size} ${className}`}>
      <div
        className="symbol-label"
        style={{
          backgroundImage: `url('${imageUrl}')`,
        }}
      >
        <img
          src={imageUrl}
          alt={url}
          onError={handleImageError}
          style={{ display: "none" }}
        />
      </div>
    </div>
  )

  return allowPopover ? (
    <Popover content={PopoverContent}>{Logo}</Popover>
  ) : (
    Logo
  )
}
