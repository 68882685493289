import { useState } from "react"
import { formatUnits } from "ethers"
import BigNumber from "bignumber.js"
import { Swap } from "../../services/models"

export const SwapRate = ({ swap }: { swap: Swap }) => {
  const { quote } = swap.quoteResponse || {}
  const fromAmountWhole = swap.fromAmount || 0
  const toAmountWhole = formatUnits(
    quote?.expectedReceiveAmount || 0,
    quote?.chainDecimal
  )

  const [isReversed, setIsReversed] = useState(false)
  const swapRate = isReversed
    ? BigNumber(fromAmountWhole).div(BigNumber(toAmountWhole))
    : BigNumber(toAmountWhole).div(BigNumber(fromAmountWhole))
  const fromTicker = swap.fromAsset?.symbol
  const toTicker = swap.toAsset?.symbol

  return (
    <div className="d-flex align-items-center gap-2 font-monospace">
      1 {isReversed ? toTicker : fromTicker} ={" "}
      <span className="text-body">
        {swapRate.gt(0)
          ? Number(swapRate).toLocaleString(undefined, {
              minimumFractionDigits: 0,
              maximumFractionDigits: swap.toAsset?.decimals,
            })
          : "0"}
      </span>
      {isReversed ? fromTicker : toTicker}
      <i
        className="bi bi-arrow-repeat text-muted fs-6"
        style={{ cursor: "pointer" }}
        onClick={() => setIsReversed(!isReversed)}
      />
    </div>
  )
}
