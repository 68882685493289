import { Tooltip } from "antd"
import { formatTokenAmount } from "../../utils/tokenUtils"
import { GetTokenSummaryResponse } from "../../../client"
import { formatPercent } from "../../utils/utils"
import { useIntl } from "react-intl"

export const PremintPctBadge = ({
  token,
  precision = 2,
  withTitle = false,
}: {
  token: GetTokenSummaryResponse
  precision?: number
  withTitle?: boolean
}) => {
  const intl = useIntl()

  const ticker = token.ticker || ""
  const { preMint, decimal, maxSupply } = token
  const preMintedPct = (preMint / maxSupply) * 100

  const preMintedBadgeType = (premintedPct: number) => {
    if (premintedPct === 0) {
      return "light-success"
    } else {
      return "secondary"
    }
  }

  return (
    <Tooltip title={formatTokenAmount(ticker, preMint, decimal)}>
      <span className={`badge badge-${preMintedBadgeType(preMintedPct)}`}>
        {withTitle ? (
          <>
            {intl.formatMessage(
              { id: "TOKEN.HEADER.PRE_MINTED_PERCENTAGE" },
              {
                preMintedPct: formatPercent(preMintedPct, precision, false),
              }
            )}
          </>
        ) : (
          formatPercent(preMintedPct, precision)
        )}
      </span>
    </Tooltip>
  )
}
