import {
  TokenTransactionResponse,
  GetTokenSummaryPriceResponse,
} from "../../../client"
import { IntlShape } from "react-intl"
import { UNIT_STRING } from "../../constants/constants"
export interface TokenTransactionType {
  type: string
  iconClass: string
  iconPath: string
}

export const getTokenTrasactionType = (
  tx: TokenTransactionResponse,
  address: string,
  intl: IntlShape
): TokenTransactionType => {
  switch (tx.operation) {
    case "MINT":
      return {
        type: intl.formatMessage({ id: "TOKEN.TRANSACTION.TYPE.MINT" }),
        iconClass: "svg-icon-primary",
        iconPath: "/media/icons/duotune/general/gen041.svg",
      }
    case "TRANSFER":
    case "SEND":
      if (tx.toAddress === address) {
        return {
          type: intl.formatMessage({ id: "TOKEN.TRANSACTION.TYPE.RECEIVED" }),
          iconClass: "svg-icon-primary",
          iconPath: "/media/icons/duotune/general/gen041.svg",
        }
      } else if (tx.fromAddress === address) {
        return {
          type: intl.formatMessage({ id: "TOKEN.TRANSACTION.TYPE.SENT" }),
          iconClass: "svg-icon-danger",
          iconPath: "/media/icons/duotune/general/gen042.svg",
        }
      } else {
        return {
          type: intl.formatMessage({ id: "TOKEN.TRANSACTION.TYPE.TRANSFER" }),
          iconClass: "svg-icon-primary",
          iconPath: "/media/icons/duotune/arrows/arr001.svg",
        }
      }
    case "DEPLOY":
      return {
        type: intl.formatMessage({ id: "TOKEN.TRANSACTION.TYPE.DEPLOY" }),
        iconClass: "svg-icon-primary",
        iconPath: "/media/icons/duotune/coding/cod002.svg",
      }
    case "LIST":
      return {
        type: intl.formatMessage({ id: "TOKEN.TRANSACTION.TYPE.LIST" }),
        iconClass: "svg-icon-primary",
        iconPath: "/media/icons/duotune/files/fil001.svg",
      }
    default:
      return {
        type: intl.formatMessage({ id: "TOKEN.TRANSACTION.TYPE.UNKNOWN" }),
        iconClass: "svg-icon-warning",
        iconPath: "",
      }
  }
}

export const getTokenDisplayPrice = ({
  price,
  priceUnit,
}: {
  price?: GetTokenSummaryPriceResponse
  priceUnit?: string
}) => {
  const { floorPrice = 0, priceInUsd = 0 } = price || {}
  if (!price || !floorPrice || !priceInUsd) {
    return "-"
  }

  return priceUnit === "KAS"
    ? `${floorPrice.toFixed(8)} ${UNIT_STRING}`
    : `$${priceInUsd.toLocaleString(undefined, {
        maximumFractionDigits: 8,
      })}`
}
