export const PriceDiffBadge = ({
  diff,
  precision = 2,
}: {
  diff: number
  precision?: number
}) => {
  if (!diff) return null

  return (
    <span
      className={`badge badge-${diff > 0 ? "light-success" : "light-danger"}`}
    >
      {diff > 0 ? "+" : ""}
      {diff.toLocaleString(undefined, { maximumFractionDigits: precision })}%
    </span>
  )
}
