import { BigNumber } from "bignumber.js"
import { TokenIcon } from "../../../components/partials/TokenIcon"
import { useGlobalData } from "../../../utils/GlobalDataProvider"
import { Tooltip } from "antd"
import { RESERVED_KAS_FOR_GAS } from "../utils"
import { useIntl } from "react-intl"

interface SwapInputCardProps {
  type: "from" | "to"
  amount: number | BigNumber
  tokenTicker: string
  tokenIconUrl: string
  tokenPriceUSD: number
  outAmountUsd?: number
  onAmountChange?: (value: string) => void
  balance?: string
  onMaxClick?: () => void
}

export const SwapInputCard: React.FC<SwapInputCardProps> = ({
  type,
  amount,
  tokenTicker,
  tokenIconUrl,
  tokenPriceUSD,
  outAmountUsd = 0,
  onAmountChange,
  balance,
  onMaxClick,
}) => {
  const intl = useIntl()
  const { marketData } = useGlobalData()
  const isFromCard = type === "from"
  const title = isFromCard
    ? intl.formatMessage({ id: "SELL" })
    : intl.formatMessage({ id: "YOU_RECEIVE" })

  const formattedAmount = isFromCard
    ? amount === 0
      ? ""
      : amount
    : BigNumber.isBigNumber(amount) && amount.gt(0)
    ? Number(amount)
    : ""

  const kasPrice = marketData?.price || 0

  const getTotalUSDAmount = () => {
    let totalUSDAmount
    const kasUSDAmount = Number(amount) * kasPrice
    const tokenUSDAmount = Number(amount) * tokenPriceUSD

    if (type === "from") {
      totalUSDAmount = tokenTicker === "KAS" ? kasUSDAmount : tokenUSDAmount
    } else {
      totalUSDAmount = outAmountUsd // Just use outAmountUsd from quote
    }

    if (!totalUSDAmount || totalUSDAmount <= 0) return "0.00"

    return totalUSDAmount.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  }

  return (
    <div className="card bg-body border border-2">
      <div className="card-body p-5">
        <div className="d-flex justify-content-between">
          <div className={`text-muted fs-5 fw-semibold`}>{title}</div>
        </div>
        <div className="d-flex align-items-center">
          <div className="flex-grow-1">
            {isFromCard ? (
              <input
                type="number"
                className={`form-control form-control-lg border-0 fs-1 fw-bold text-dark`}
                style={{ padding: "0" }}
                value={formattedAmount.toString()}
                onChange={(e) => onAmountChange?.(e.target.value)}
                placeholder="0"
              />
            ) : (
              <input
                type="number"
                className="form-control form-control-lg border-0 fs-1 fw-bold text-dark"
                style={{ padding: "0" }}
                value={formattedAmount.toString()}
                readOnly
                placeholder="0"
              />
            )}
          </div>
          <div className="d-flex align-items-center gap-2 ps-4 py-2 fs-6">
            <TokenIcon url={tokenIconUrl} size="25px" allowPopover={false} />
            <span className="fw-bold">{tokenTicker}</span>
          </div>
        </div>

        <div className="d-flex justify-content-between align-items-center">
          <div className="text-muted fs-6" style={{ maxWidth: "100%" }}>
            ${getTotalUSDAmount()}
          </div>
          {isFromCard && balance && (
            <div className="d-flex align-items-center gap-2 fs-6">
              <div className="text-muted">
                {balance} {tokenTicker}
              </div>
              <Tooltip
                title={
                  tokenTicker === "KAS"
                    ? intl.formatMessage(
                        { id: "MAX_KAS_FOR_GAS" },
                        { amount: RESERVED_KAS_FOR_GAS }
                      )
                    : ""
                }
                placement="bottom"
              >
                <button
                  className="btn btn-sm btn-light p-1 px-2"
                  onClick={onMaxClick}
                >
                  {intl.formatMessage({ id: "MAX" })}
                </button>
              </Tooltip>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
