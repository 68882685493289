import { Badge } from "antd"
import { useSwapService } from "../services/SwapService"
import React from "react"
import { SwapStatus } from "../services/models"
import { useIntl } from "react-intl"

interface SwapCardHeaderProps {
  ticker?: string
  onHistoryClick: () => void
  onSettingsClick: () => void
}

const PENDING_SWAP_STATUSES = [
  SwapStatus.PAID,
  SwapStatus.SIGNED,
  SwapStatus.SUBMITTED,
]

const SwapCardHeader: React.FC<SwapCardHeaderProps> = ({
  ticker,
  onHistoryClick,
  onSettingsClick,
}) => {
  const intl = useIntl()
  const btnClass = "btn btn-secondary btn-sm py-3 px-4"
  const { swaps } = useSwapService()
  const pendingCount = swaps.filter((swap) =>
    PENDING_SWAP_STATUSES.includes(swap.swapStatus)
  ).length

  return (
    <div className="d-flex justify-content-between align-items-center mb-4">
      <h5 className="mb-0 fs-3 fw-bold d-flex align-items-center">
        <span>{intl.formatMessage({ id: "SWAP_X" }, { ticker })}</span>
        <span className="badge badge-danger ms-2">
          {intl.formatMessage({ id: "NEW" })}
        </span>
      </h5>
      <div className="d-flex gap-2">
        <Badge count={pendingCount} offset={[-5, 5]}>
          <button className={btnClass} onClick={onHistoryClick}>
            <i className="bi bi-clock-history"></i>
          </button>
        </Badge>
        <button className={btnClass} onClick={onSettingsClick}>
          <i className="bi bi-gear"></i>
        </button>
      </div>
    </div>
  )
}

export { SwapCardHeader }
