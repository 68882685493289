interface DonorProps {
  name: string
  amount: number
}

export const donors: DonorProps[] = [
  {
    name: "Dablacksplash",
    amount: 1000,
  },
  {
    name: "Tim",
    amount: 30000,
  },
  {
    name: "AvogHadro",
    amount: 500,
  },
  {
    name: "Bubblegum Lightning",
    amount: 100,
  },
  {
    name: "coderofstuff",
    amount: 100,
  },
  {
    name: "bigSword",
    amount: 1000,
  },
  {
    name: "韩跑跑",
    amount: 619,
  },
  {
    name: "小凡",
    amount: 619,
  },
  {
    name: "Crypto sky",
    amount: 619,
  },
  {
    name: "Kas God",
    amount: 18888,
  },
  {
    name: "Amao",
    amount: 20000,
  },
  {
    name: "牛角巷.DSP",
    amount: 1032,
  },
  {
    name: "李羡鱼",
    amount: 200,
  },
  {
    name: "Aniket Masih",
    amount: 101,
  },
  {
    name: "Conrad Gair",
    amount: 15000,
  },
  {
    name: "PCT",
    amount: 470,
  },
  {
    name: "JP Hopmans",
    amount: 9970,
  },
  {
    name: "ThatKindOfBot",
    amount: 100,
  },
  {
    name: "@xImHoveR (via Dolphin Club $KAS Tees)",
    amount: 200,
  },
  {
    name: "Myx",
    amount: 50,
  },
  {
    name: "Controllinghand",
    amount: 100,
  },
  {
    name: "Zilliax28 DAGKNIGHT ❤️",
    amount: 50,
  },
  {
    name: "Eichhörnchenchen",
    amount: 50,
  },
  {
    name: "Chiefthain",
    amount: 200,
  },
  {
    name: "premi76",
    amount: 50,
  },
  {
    name: "hashdawg 🇵🇭",
    amount: 99.99,
  },
  {
    name: "GKing",
    amount: 100,
  },
  {
    name: "$FAST",
    amount: 1500,
  },
  {
    name: "Bitcoin Hoodie Guy",
    amount: 50,
  },
  {
    name: "$KRYPTO Team",
    amount: 100,
  },
  {
    name: "$Anime Team",
    amount: 100,
  },
  {
    name: "$MEGA Team",
    amount: 777,
  },
  {
    name: "KaspaTokenForum.com",
    amount: 100,
  },
  {
    name: "0xSidlly",
    amount: 1000,
  },
]
