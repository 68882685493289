export const showTxnDetailKey = "showTxnDetail"
export const showAddressDistAbsoluteDiffKey = "showAddressDistAbsoluteDiff"
export const krc20PriceUnitKey = "krc20PriceUnit"
export const dashboardMetricsKey = "dashboardMetrics"
export const dashboardLiveTransactionShowCoinbaseKey =
  "dashboardLiveTransactionShowCoinbase"

export const bookmarkedTokensKey = "bookmarkedTokens"
export const watchlistEnabledKey = "watchlistEnabled"

export const swapSlippageKey = "swapSlippage"
