import { Tooltip } from "antd"
import { formatTokenAmount } from "../../utils/tokenUtils"
import { GetTokenSummaryResponse } from "../../../client"
import { useIntl } from "react-intl"
import { formatPercent } from "../../utils/utils"
import { Link } from "react-router-dom"
import { getAddressRoute } from "../../hooks/navigator"
import { BURN_ADDRESS } from "../../constants/constants"

export const BurnPctBadge = ({
  token,
  precision = 2,
  withTitle = false,
}: {
  token: GetTokenSummaryResponse
  precision?: number
  withTitle?: boolean
}) => {
  const intl = useIntl()

  const ticker = token.ticker || ""
  const { burned, decimal, maxSupply } = token
  const burnedPct = (burned / (maxSupply / 10 ** decimal)) * 100

  return (
    <Tooltip title={formatTokenAmount(ticker, burned, 0)}>
      <Link
        className="badge badge-secondary"
        to={getAddressRoute(BURN_ADDRESS)}
      >
        {withTitle ? (
          <>
            {intl.formatMessage(
              { id: "TOKEN.HEADER.BURNED_PERCENTAGE" },
              {
                burnedPct: formatPercent(burnedPct, precision, false),
              }
            )}
          </>
        ) : (
          `${burnedPct}%`
        )}
      </Link>
    </Tooltip>
  )
}
