import { useLocalStorageState } from "ahooks"
import { Swap } from "./models"
import { useState, useEffect } from "react"

export const useSwapService = () => {
  const STORAGE_KEY = "swaps_history_v1"
  const [swapsData, setSwapsData] = useLocalStorageState<[string, Swap][]>(
    STORAGE_KEY,
    {
      defaultValue: [],
      listenStorageChange: true, // THIS IS IMPORTANT
    }
  )

  const [swaps, setSwaps] = useState<Swap[]>([])

  useEffect(() => {
    if (!swapsData) return
    const swapInstances = swapsData.map(([id, data]) => {
      const swap = new Swap()
      Object.assign(swap, data)
      return swap
    })
    setSwaps(swapInstances.sort((a, b) => b.createdAt - a.createdAt))
  }, [swapsData])

  const updateSwap = (updatedSwap: Swap) => {
    if (!swapsData) return
    const newSwapsData = swapsData.filter(([id]) => id !== updatedSwap.id)
    newSwapsData.push([updatedSwap.id, updatedSwap])
    setSwapsData(newSwapsData)
  }

  return {
    swaps,
    updateSwap,
  }
}
