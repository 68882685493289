/* eslint-disable react/jsx-no-target-blank */
import type { UploadProps } from "antd"
import { Alert, Button, Form, Input, message, Result, Upload } from "antd"
import {
  GetTokenSummaryResponse,
  PostTokenInfoDto,
  TokenService,
} from "../../../../client"
import { PlusOutlined } from "@ant-design/icons"
import ImgCrop from "antd-img-crop"
import { generateNonce } from "../../../utils/secrets"
import { useState } from "react"
import { Typography } from "antd"
import { PasteIcon } from "../../../components/PasteIcon"

const { Paragraph } = Typography

type FieldType = {
  ticker: string
  urlIcon: string
  twitter: string
  website: string
  telegram: string
  discord: string
}

const ResultScreen = ({
  submissionId,
  ticker,
}: {
  submissionId: string
  ticker?: string
}) => {
  const verificationMessage = `I have submitted the token info for ${ticker} to Kas.fyi. Submission ID: ${submissionId}`

  return (
    <Result
      status="success"
      title="Token Info Submitted"
      subTitle={
        <span className="text-body">
          <div className="fs-5 fw-semibold">
            To speed up the verification process, please post the following
            message on the project's Twitter account. Failure to post this
            message might delay the review process.
          </div>
          <Paragraph>
            <pre className="text-start">
              {verificationMessage} <PasteIcon value={verificationMessage} />
            </pre>
          </Paragraph>
        </span>
      }
    />
  )
}

const TokenDetailsEditForm = ({
  token,
}: {
  token?: GetTokenSummaryResponse
}) => {
  const [form] = Form.useForm()
  const [submissionId, setSubmissionId] = useState<string | null>(null)
  const [submitting, setSubmitting] = useState(false)

  const findSocialLink = (type: string) => {
    return token?.socialLinks?.find((link) => link.type === type)?.url
  }

  const handleUpload: UploadProps["customRequest"] = async ({
    file,
    onSuccess,
    onError,
  }) => {
    form.setFieldsValue({ urlIcon: "" })

    const formData = new FormData()
    formData.append("image", file)

    try {
      const response = await fetch(
        `https://api.imgbb.com/1/upload?expiration=1209600&key=a6992a145ae05913835cfdb3f0ed3646&name=${token?.ticker}`,
        {
          method: "POST",
          body: formData,
        }
      )
      const data = await response.json()
      if (data.success) {
        onSuccess?.(data.data)
        form.setFieldsValue({ urlIcon: data.data.url })
        message.success(`file uploaded successfully`)
      } else {
        onError?.(new Error("Upload failed"))
        message.error(`file upload failed.`)
      }
    } catch (error) {
      onError?.(error as Error)
      message.error(`file upload failed.`)
    }
  }

  const onFinish = async () => {
    const payload = form.getFieldsValue() as PostTokenInfoDto
    // Compare with previous values, see if there are any changes
    if (
      payload.urlIcon === token?.iconUrl &&
      payload.twitter === findSocialLink("twitter") &&
      payload.telegram === findSocialLink("telegram") &&
      payload.discord === findSocialLink("discord") &&
      payload.website === findSocialLink("website")
    ) {
      message.error("No changes detected")
      return
    }

    // At least one social link is required
    if (
      !payload.twitter &&
      !payload.telegram &&
      !payload.discord &&
      !payload.website
    ) {
      message.error("At least one social link is required")
      return
    }

    setSubmitting(true)

    payload.nonce = await generateNonce()
    const response = await TokenService.tokenControllerPostTokenInfo(payload)

    if (response) {
      setSubmissionId(response as string)
    } else {
      message.error("Failed to update token info")
    }

    setSubmitting(false)
  }

  return (
    <>
      {submissionId && (
        <ResultScreen submissionId={submissionId} ticker={token?.ticker} />
      )}
      {!submissionId ? (
        <>
          <Alert
            description={
              <>
                <span className="text-body">
                  To update your token metadata on Kas.fyi, please fill out this
                  form, and we will process it within{" "}
                  <span className="text-primary">48 hours</span>.
                </span>
              </>
            }
            type="info"
            showIcon
            className="mb-6"
          />

          <Form
            layout="vertical"
            initialValues={{ remember: true }}
            autoComplete="off"
            onFinish={onFinish}
            form={form}
          >
            <Form.Item<FieldType>
              label="Ticker"
              name="ticker"
              initialValue={token?.ticker}
            >
              <Input readOnly disabled />
            </Form.Item>

            <Form.Item
              label="Logo"
              name="urlIcon"
              required
              extra="Allowed file types: png, jpeg, jpg"
              initialValue={token?.iconUrl}
              rules={[{ required: true }]}
            >
              <ImgCrop rotationSlider>
                <Upload
                  listType="picture-circle"
                  maxCount={1}
                  accept="image/png,image/jpeg,image/jpg"
                  showUploadList={{ showPreviewIcon: false }}
                  defaultFileList={
                    token?.iconUrl
                      ? [{ url: token.iconUrl, uid: "1", name: "logo" }]
                      : []
                  }
                  name="file"
                  customRequest={handleUpload}
                  onRemove={() => {
                    form.setFieldsValue({ urlIcon: "" })
                  }}
                >
                  <button
                    className="text-body"
                    style={{ border: 0, background: "none" }}
                    type="button"
                  >
                    <PlusOutlined />
                    <div style={{ marginTop: 8 }}>Upload</div>
                  </button>
                </Upload>
              </ImgCrop>
            </Form.Item>

            <Form.Item<FieldType>
              label="Website"
              name="website"
              initialValue={findSocialLink("website")}
              rules={[{ type: "url" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item<FieldType>
              label="Twitter"
              name="twitter"
              initialValue={findSocialLink("twitter")}
              rules={[{ type: "url" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item<FieldType>
              label="Telegram"
              name="telegram"
              initialValue={findSocialLink("telegram")}
              rules={[{ type: "url" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item<FieldType>
              label="Discord"
              name="discord"
              initialValue={findSocialLink("discord")}
              rules={[{ type: "url" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                disabled={submitting}
                loading={submitting}
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </>
      ) : null}
    </>
  )
}

export default TokenDetailsEditForm
