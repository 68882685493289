import { Drawer, FloatButton, notification } from "antd"
import { useSwapService } from "../../pages/swap/services/SwapService"
import { useMemo, useState } from "react"
import { Swap, SwapStatus } from "../../pages/swap/services/models"
import { SwapHistory } from "../../pages/swap/components/SwapHistory"
import { useRequest } from "ahooks"
import { SwapApiService } from "../../../client/services/SwapApiService"
import { useKasware } from "../../hooks/useKasware"
import { formatUnits } from "ethers"
import { Link } from "react-router-dom"
import { getTransactionRoute } from "../../hooks/navigator"
import { useIntl } from "react-intl"

export const SwapStatusFloatButton = () => {
  const intl = useIntl()
  const [api, contextHolder] = notification.useNotification()
  const { isKaswareInstalled } = useKasware()
  const { swaps, updateSwap } = useSwapService()
  const pendingSwaps = useMemo(() => {
    return swaps.filter((swap) => swap.swapStatus === SwapStatus.SUBMITTED)
  }, [swaps])
  const [swapHistoryOpen, setSwapHistoryOpen] = useState(false)

  const showNotification = (swap: Swap) => {
    const fromTicker = swap.fromAsset?.symbol
    const toTicker = swap.toAsset?.symbol
    const decimal = swap.toAsset?.decimals

    if (swap.swapStatus === SwapStatus.COMPLETED) {
      const amount = swap.swapOrder?.amountOut
      const parsedAmount = formatUnits(amount!, decimal!)
      const execHash = swap.swapOrder?.execHash
      api.success({
        message: intl.formatMessage({ id: "SWAP_COMPLETED" }),
        description: (
          <div>
            {intl.formatMessage(
              { id: "YOU_RECEIVED_TOKEN_X" },
              {
                amount: (
                  <Link to={getTransactionRoute(execHash!)}>
                    {parsedAmount} {toTicker}
                  </Link>
                ),
              }
            )}
          </div>
        ),
        placement: "top",
      })
    } else if (swap.swapStatus === SwapStatus.DROPPED) {
      api.error({
        message: intl.formatMessage({ id: "SWAP_DROPPED" }),
        description: intl.formatMessage(
          { id: "SWAP_DROPPED_DESCRIPTION" },
          {
            toTicker,
            fromTicker,
          }
        ),
        placement: "top",
      })
    } else if (swap.swapStatus === SwapStatus.REFUNDED) {
      api.error({
        message: intl.formatMessage({ id: "SWAP_REFUNDED" }),
        description: intl.formatMessage(
          { id: "SWAP_REFUNDED_DESCRIPTION" },
          {
            toTicker,
          }
        ),
        placement: "top",
      })
    }
  }

  useRequest(
    async () => {
      const orders = await Promise.all(
        pendingSwaps.map(async (swap) => {
          if (!swap.txnId) return null
          try {
            const order = await SwapApiService.swapControllerGetSwapOrder(
              swap.txnId
            )
            return { swap, order }
          } catch (e) {
            console.error(`Failed to fetch order ${swap.orderId}:`, e)
            return null
          }
        })
      )

      for (const result of orders) {
        if (!result) continue
        const { swap, order } = result
        const orderStatus = order?.order?.status

        if (orderStatus === "Succeeded") {
          swap.onSwapCompleted(order?.order)
          updateSwap(swap)
        } else if (orderStatus === "Dropped") {
          swap.onSwapDropped()
          updateSwap(swap)
        } else if (orderStatus === "Refunded") {
          swap.onSwapRefunded()
          updateSwap(swap)
        }

        showNotification(swap)
      }
    },
    {
      pollingInterval: 5 * 1e3,
      ready: pendingSwaps.length > 0,
    }
  )

  if (!isKaswareInstalled) return null // TOOD: Might need to change later when more wallets are supported

  return (
    <>
      {contextHolder}
      <Drawer
        title={intl.formatMessage({ id: "SWAP_HISTORY" })}
        onClose={() => setSwapHistoryOpen(false)}
        open={swapHistoryOpen}
        placement="bottom"
        destroyOnClose
        className="bg-body"
        height={"60%"}
      >
        <SwapHistory />
      </Drawer>
      <FloatButton
        onClick={() => setSwapHistoryOpen(true)}
        tooltip={intl.formatMessage({ id: "CHECK_TOKEN_SWAP_HISTORY" })}
        badge={{ count: pendingSwaps.length }}
      />
    </>
  )
}
