import { trackEvent } from "../../utils/utils"

export const trackSwapOrderSubmitted = ({ token }: { token: string }) => {
  trackEvent("swap_order_submitted", {
    on_token_page: token,
  })
}

export const trackSwapOrderFailed = ({ token }: { token: string }) => {
  trackEvent("swap_order_failed", {
    on_token_page: token,
  })
}
