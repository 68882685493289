/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react"
import { useIntl } from "react-intl"
import { GetTokenSummaryResponse } from "../../../../client"

import { Drawer, Skeleton } from "antd"
import { TokenIcon } from "../../../components/partials/TokenIcon"
import { PriceDiffBadge } from "../../../components/PriceDiffBadge"
import { TokenMintedProgressiveBar } from "../../../components/partials/TokenMintedProgressiveBar"
import { TokenTradingVolume } from "../../../components/partials/TokenTradingVolume"
import { useGlobalData } from "../../../utils/GlobalDataProvider"
import { Krc20PriceUnitToggleButton } from "../../../components/partials/Krc20PriceUnitToggleButton"
import TokenDetailsEditForm from "./TokenDetailsEditForm"
import {
  LowLiquidityWarningIcon,
  MIN_TRADE_VOLUME_IN_USD,
} from "../../../components/partials/LowLiquidityWarningIcon"
import { BookmarkTokenIcon } from "../../../components/partials/BookmarkTokenIcon"
import { SwapCard } from "../../swap/components/SwapCard"
import { getTokenDisplayPrice } from "../utils"
import { DashSeparator } from "../../../components/partials/DashSeparator"

const Space = () => <div className="my-5"></div>

const labelClass = "fw-semibold text-muted text-break fs-6"
const valueClass =
  "fw-semibold text-dark text-break fs-6 d-flex align-items-center"

const renderCell = (label: React.ReactNode, value: React.ReactNode) => {
  return (
    <div className="d-flex flex-wrap">
      <div>
        <div className={`d-flex align-items-center mb-1`}>
          <span className={labelClass}>{label}</span>
        </div>
        {value}
      </div>
    </div>
  )
}

interface TokenHeaderProps {
  token?: GetTokenSummaryResponse
  loading?: boolean
}

const TokenHeader: React.FC<TokenHeaderProps> = ({ token, loading }) => {
  const intl = useIntl()
  const [open, setOpen] = useState(false)
  const { krc20PriceUnit } = useGlobalData()

  const showDrawer = () => {
    if (token?.ticker) {
      setOpen(true)
    }
  }

  const onClose = () => {
    setOpen(false)
  }

  const { marketCapInUsd = 0 } = token?.price || {}
  const ticker = token?.ticker || ""

  const displayPrice = getTokenDisplayPrice({
    price: token?.price,
    priceUnit: krc20PriceUnit,
  })

  const change24h = token?.price?.change24h || 0
  const change24hInKas = token?.price?.change24hInKas || 0
  const tradeVolume = token?.tradeVolume
  const priceChange = krc20PriceUnit === "KAS" ? change24hInKas : change24h

  const headerLoading = loading && !token

  return (
    <div className={`card mb-6`}>
      <Drawer
        title={intl.formatMessage({ id: "TOKEN.HEADER.EDIT_TOKEN_DETAILS" })}
        onClose={onClose}
        open={open}
        size="large"
        destroyOnClose
        className="bg-body"
      >
        <TokenDetailsEditForm token={token} />
      </Drawer>
      <div className="card-header border-0">
        <div className="card-title m-0">
          <h3 className="fw-bold m-0 d-flex align-items-center">
            <TokenIcon url={token?.iconUrl} className="me-3" size="25px" />
            <span className="me-2">
              {intl.formatMessage(
                { id: "TOKEN.HEADER.TOKEN_DETAILS" },
                { ticker }
              )}
            </span>
            <i
              role="button"
              className="fs-5 bi bi-pencil-square text-muted text-hover-primary mx-1"
              onClick={showDrawer}
            ></i>
            <BookmarkTokenIcon ticker={ticker} />
          </h3>
        </div>
      </div>

      <div className="card-body border-top p-9">
        {headerLoading ? (
          <Skeleton active />
        ) : (
          <>
            {renderCell(
              <div className="d-flex align-items-center gap-2">
                {intl.formatMessage({ id: "PRICE" })}
                <span>({krc20PriceUnit})</span>
              </div>,
              <div className={valueClass}>
                <div className="d-flex align-items-center">
                  <span className="me-2">{displayPrice}</span>
                  {priceChange ? (
                    <PriceDiffBadge diff={priceChange} precision={2} />
                  ) : null}
                </div>

                <span className="ms-2">
                  <Krc20PriceUnitToggleButton />
                </span>
              </div>
            )}

            <Space />
            {renderCell(
              intl.formatMessage({ id: "DASHBOARD.MARKET.MARKET_CAP.TITLE" }),
              <div className={valueClass}>
                {marketCapInUsd > 0 ? (
                  <>
                    {`$${marketCapInUsd.toLocaleString(undefined, {
                      maximumFractionDigits: 0,
                    })}`}
                    {!tradeVolume ||
                    tradeVolume.amountInUsd < MIN_TRADE_VOLUME_IN_USD ? (
                      <LowLiquidityWarningIcon />
                    ) : null}
                  </>
                ) : (
                  "-"
                )}
              </div>
            )}

            <Space />
            {renderCell(
              intl.formatMessage({ id: "TRADING_VOLUME_24H" }),
              <div className={valueClass}>
                {tradeVolume && tradeVolume.amountInUsd > 0 ? (
                  <TokenTradingVolume tradeVolume={tradeVolume} />
                ) : (
                  "-"
                )}
              </div>
            )}

            <Space />
            {renderCell(
              intl.formatMessage({ id: "TOKEN.HEADER.TOTAL_MINTED" }),
              <div className={valueClass}>
                {token && (
                  <div className="w-150px">
                    <TokenMintedProgressiveBar token={token} fullTotalSupply />
                  </div>
                )}
              </div>
            )}

            {token?.tradeEnabled && (
              <>
                <DashSeparator />
                <SwapCard token={token} loading={loading} />
              </>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export { TokenHeader }
