/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum OperaionType {
    DEPLOY = 'DEPLOY',
    MINT = 'MINT',
    TRANSFER = 'TRANSFER',
    LIST = 'LIST',
    SEND = 'SEND',
}
