export const TosWrapper = () => {
  return (
    <div className="container py-10">
      <div className="card">
        <div className="card-body">
          <h1 className="text-dark mb-8">Terms of Service</h1>
          <p className="text-muted mb-8">Last Updated: 2024-12-08</p>

          <div className="mb-8">
            <h2 className="text-dark mb-4">1. Service Overview</h2>
            <p>
              kas.fyi provides blockchain exploration tools and related services
              but does not process transactions, hold funds, or guarantee the
              success of transactions. Blockchain transactions are irreversible
              and final once processed. kas.fyi acts solely as an interface for
              users to interact with blockchain data and third-party services,
              including but not limited to:
            </p>
            <ul className="list-disc pl-8 mt-4">
              <li>Block explorer services for viewing blockchain data</li>
              <li>Token swap functionality through third-party providers</li>
              <li>Transaction tracking and analysis tools</li>
            </ul>
          </div>

          <div className="mb-8">
            <h2 className="text-dark mb-4">2. Disclaimer of Warranties</h2>
            <p>
              THE SERVICES ARE PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT
              WARRANTIES OF ANY KIND. WE MAKE NO WARRANTIES OR REPRESENTATIONS
              ABOUT THE ACCURACY OR COMPLETENESS OF THE SITE'S CONTENT OR THE
              CONTENT OF ANY THIRD-PARTY SERVICES. kas.fyi disclaims
              responsibility for any errors, bugs, or vulnerabilities in
              blockchain protocols or third-party integrations. We are not
              liable for service interruptions caused by external factors,
              including but not limited to network congestion, hardware or
              software failures, or cyberattacks.
            </p>
          </div>

          <div className="mb-8">
            <h2 className="text-dark mb-4">3. Limitation of Liability</h2>
            <p>
              IN NO EVENT SHALL KAS.FYI BE LIABLE FOR ANY INDIRECT, PUNITIVE,
              SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES ARISING OUT OF OR IN
              CONNECTION WITH THE SERVICES. kas.fyi's aggregate liability for
              any claims related to the Services shall not exceed $100 USD. This
              limitation applies regardless of whether kas.fyi has been advised
              of the possibility of such damages, including but not limited to:
            </p>
            <ul className="list-disc pl-8 mt-4">
              <li>The accuracy of blockchain data displayed</li>
              <li>Losses from token swaps or transactions</li>
              <li>Service interruptions or delays</li>
              <li>Actions of third-party services or providers</li>
              <li>Any unauthorized access or data breaches</li>
            </ul>
          </div>

          <div className="mb-8">
            <h2 className="text-dark mb-4">4. Token Swap Service Disclaimer</h2>
            <p>
              The Token Swap Service relies on third-party providers such as the
              Chainge API. kas.fyi does not act as a broker, custodian, or
              financial advisor. Any reliance on third-party services is at the
              user's own risk, and kas.fyi:
            </p>
            <ul className="list-disc pl-8 mt-4">
              <li>
                Does not guarantee the availability or performance of swap
                services
              </li>
              <li>
                Is not responsible for any losses from failed or delayed
                transactions
              </li>
              <li>
                Makes no representations about token values or exchange rates
              </li>
              <li>
                Is not responsible for any inaccuracies, delays, or malfunctions
                in third-party services
              </li>
            </ul>
          </div>

          <div className="mb-8">
            <h2 className="text-dark mb-4">5. User Responsibilities</h2>
            <p>
              Users must understand blockchain technology and its associated
              risks. Users are solely responsible for:
            </p>
            <ul className="list-disc pl-8 mt-4">
              <li>
                Understanding risks including loss of private keys, transaction
                failures, and token price volatility
              </li>
              <li>Verifying all transaction details before execution</li>
              <li>
                Maintaining the security of their wallets and private keys
              </li>
              <li>Compliance with applicable laws and regulations</li>
              <li>Any tax obligations arising from their activities</li>
              <li>
                Conducting their own due diligence, acknowledging that kas.fyi
                does not provide financial, investment, or legal advice
              </li>
            </ul>
          </div>

          <div className="mb-8">
            <h2 className="text-dark mb-4">6. Indemnification</h2>
            <p>
              Users agree to indemnify and hold harmless kas.fyi, its
              affiliates, and its operators from any claims, damages, or
              expenses arising out of their use of the Services or violation of
              these Terms.
            </p>
          </div>

          <div className="mb-8">
            <h2 className="text-dark mb-4">7. Modifications to Terms</h2>
            <p>
              kas.fyi reserves the right to modify these Terms at any time. Your
              continued use of the Services constitutes acceptance of the
              modified Terms.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TosWrapper
