/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetSwapOrderResponse } from '../models/GetSwapOrderResponse';
import type { GetSwapQuoteResponse } from '../models/GetSwapQuoteResponse';
import type { SubmitOrderDto } from '../models/SubmitOrderDto';
import type { SubmitOrderResponse } from '../models/SubmitOrderResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SwapApiService {

    /**
     * @param slippage Slippage percentage
     * @param fromAmount User input amount, in whole units
     * @param toTicker To ticker
     * @param fromTicker From ticker
     * @returns GetSwapQuoteResponse
     * @throws ApiError
     */
    public static swapControllerGetSwapQuote(
        slippage: string,
        fromAmount: string,
        toTicker: string,
        fromTicker: string,
    ): CancelablePromise<GetSwapQuoteResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/swap/quote',
            query: {
                'slippage': slippage,
                'fromAmount': fromAmount,
                'toTicker': toTicker,
                'fromTicker': fromTicker,
            },
        });
    }

    /**
     * @param requestBody
     * @returns SubmitOrderResponse
     * @throws ApiError
     */
    public static swapControllerSubmitOrder(
        requestBody: SubmitOrderDto,
    ): CancelablePromise<SubmitOrderResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/swap/submitOrder',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param transactionId Transaction ID of the payment
     * @returns GetSwapOrderResponse
     * @throws ApiError
     */
    public static swapControllerGetSwapOrder(
        transactionId: string,
    ): CancelablePromise<GetSwapOrderResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/swap/order',
            query: {
                'transactionId': transactionId,
            },
        });
    }

}
