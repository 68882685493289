/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"
import { useIntl } from "react-intl"
import {
  AddressTag as AddressTagModel,
  GetAddressTokensResponse,
} from "../../../../client"
import { AddressTag } from "../../../components/partials/AddressTag"
import { FiatAmountBadge } from "../../../components/FiatAmountBadge"
import { PasteIcon } from "../../../components/PasteIcon"
import { QrIcon } from "../../../components/QrIcon"
import { formatKaspaAmount, kaspaAmountToInt } from "../../../utils/utils"
import {
  Button,
  Skeleton,
  Tooltip,
  Space as AntdSpace,
  Popover,
  Badge,
} from "antd"
import { ALTERNATIVE_EXPLORER_URL_ADDRESS } from "../../../constants/constants"
import { getAddressBalanceCategory } from "../utils"
import { DownOutlined } from "@ant-design/icons"
import { AddressTokensList } from "./AddressTokensList"
import { Krc20UIEnabled } from "../../../utils/feature"

const Space = () => <div className="my-5"></div>

const labelClass = "fw-semibold text-muted text-break fs-6"
const valueClass = "fw-semibold text-dark text-break fs-6"

const renderCell = (
  label: string,
  value: React.ReactNode,
  tags?: AddressTagModel[]
) => {
  return (
    <div className="d-flex flex-wrap">
      <div>
        <div className={`d-flex align-items-center mb-1`}>
          <span className={labelClass}>{label}</span>
          {tags?.map((tag, index) => {
            const className = index === 0 ? "mx-2" : "me-2"
            return (
              <AddressTag
                tag={tag}
                className={className}
                key={`tag-${index}`}
              />
            )
          })}
        </div>
        {value}
      </div>
    </div>
  )
}

interface AddressHeaderProps {
  address: string
  balance: number
  transactionCount?: number
  tags?: AddressTagModel[]
  infoLoading?: boolean
  tokens?: GetAddressTokensResponse[]
  tokensLoading?: boolean
}

const AddressHeader: React.FC<AddressHeaderProps> = ({
  address,
  balance,
  transactionCount,
  tags,
  infoLoading,
  tokens = [],
  tokensLoading,
}) => {
  const intl = useIntl()
  const balanceCategory = getAddressBalanceCategory(intl, balance)

  return (
    <div className={`card mb-6`}>
      <div className="card-header border-0">
        <div className="card-title m-0">
          <h3 className="fw-bold m-0">
            {intl.formatMessage({ id: "ADDRESS.DETAIL.TITLE" })}
            <Tooltip title="To explorer.kaspa.org">
              <a
                role="button"
                className="fa-solid fa-arrow-up-right-from-square text-hover-primary fs-7 text-dark ms-3 mt-1"
                href={`${ALTERNATIVE_EXPLORER_URL_ADDRESS}${address}`}
                target="_blank"
              ></a>
            </Tooltip>
            <Badge>
              <i
                role="button"
                data-bs-toggle="modal"
                data-bs-target="#monitor-address-modal"
                className="fa-regular fa-bell fs-5 ms-4 text-dark text-hover-primary"
              ></i>
            </Badge>
          </h3>
        </div>
      </div>

      <div className="card-body border-top p-9">
        {renderCell(
          intl.formatMessage({ id: "ADDRESS" }),
          <div className={`${valueClass} d-flex align-items-center`}>
            {address}
            <PasteIcon value={address} />
            <QrIcon value={address} />
          </div>,
          tags
        )}

        <Space />
        {renderCell(
          intl.formatMessage({ id: "BALANCE" }),
          infoLoading ? (
            <Skeleton.Button size={"small"} active style={{ height: "17px" }} />
          ) : (
            <div className={`${valueClass} d-flex align-items-center`}>
              {formatKaspaAmount(balance)}
              <FiatAmountBadge kaspaAmount={kaspaAmountToInt(balance)} />
            </div>
          ),
          [...(balanceCategory ? [balanceCategory] : [])]
        )}

        <Space />
        {renderCell(
          intl.formatMessage({ id: "TRANSACTION_COUNT" }),
          infoLoading ? (
            <Skeleton.Button size={"small"} active style={{ height: "17px" }} />
          ) : (
            <div className={`${valueClass}`}>
              {transactionCount?.toLocaleString()}
            </div>
          )
        )}

        {Krc20UIEnabled && (
          <>
            <Space />
            {renderCell(
              intl.formatMessage({ id: "ADDRESS.TOKEN_HOLDINGS" }),
              <div className={`${valueClass}`}>
                <div className="d-flex mb-4">
                  <Popover
                    overlayInnerStyle={{ padding: 1 }}
                    autoAdjustOverflow={false}
                    content={<AddressTokensList tokens={tokens || []} />}
                    trigger="click"
                    placement="bottomLeft"
                    arrow={false}
                  >
                    <Button
                      loading={tokensLoading}
                      disabled={tokens.length === 0}
                    >
                      <AntdSpace>
                        {tokens.length > 0
                          ? intl.formatMessage(
                              { id: "ADDRESS.X_TOKENS" },
                              { x: tokens.length }
                            )
                          : intl.formatMessage({ id: "ADDRESS.NO_TOKENS" })}
                        <DownOutlined />
                      </AntdSpace>
                    </Button>
                  </Popover>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export { AddressHeader }
