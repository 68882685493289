import { ConfigProvider, theme } from "antd"
import { Suspense } from "react"
import { Outlet } from "react-router-dom"
import { I18nProvider } from "../_metronic/i18n/i18nProvider"
import { LayoutProvider, LayoutSplashScreen } from "../_metronic/layout/core"
import { MasterInit } from "../_metronic/layout/MasterInit"
import { useThemeMode } from "../_metronic/partials/layout/theme-mode/ThemeModeProvider"
import {
  DARK_BG_COLOR,
  DEFAULT_TITLE,
  PRIMARY_COLOR,
} from "./constants/constants"
import { GlobalDataProvider } from "./utils/GlobalDataProvider"
import { HelmetWrapper } from "./utils/HelmetWrappper"
import { SwapStatusFloatButton } from "./components/partials/SwapStatusFloatButton"

const App = () => {
  const { defaultAlgorithm, darkAlgorithm } = theme
  const { mode } = useThemeMode()
  const antThemeAlgorithm = () =>
    mode === "dark"
      ? darkAlgorithm({
          ...theme.defaultSeed,
          colorBgBase: DARK_BG_COLOR,
          colorPrimary: PRIMARY_COLOR,
        })
      : defaultAlgorithm({ ...theme.defaultSeed, colorPrimary: PRIMARY_COLOR })

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <HelmetWrapper title={DEFAULT_TITLE} />
      <I18nProvider>
        <LayoutProvider>
          <ConfigProvider theme={{ algorithm: antThemeAlgorithm }}>
            <GlobalDataProvider>
              <Outlet />
              <MasterInit />
              <SwapStatusFloatButton />
            </GlobalDataProvider>
          </ConfigProvider>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  )
}

export { App }
