import { useRafInterval } from "ahooks"
import { useState, useEffect, useCallback } from "react"

export interface KaswareKrc20Balance {
  balance: string
  dec: string
  locked: string
  opScoreMod: string
  tick: string
}

declare global {
  interface Window {
    kasware?: {
      requestAccounts(): Promise<string[]>
      getAccounts(): Promise<string[]>
      getVersion(): Promise<string>
      getNetwork(): Promise<string>
      switchNetwork(network: "mainnet" | "testnet" | "devnet"): Promise<void>
      disconnect(origin: string): Promise<void>
      getPublicKey(): Promise<string>
      getBalance(): Promise<{
        confirmed: number
        unconfirmed: number
        total: number
      }>
      getKRC20Balance(): Promise<KaswareKrc20Balance[]>
      sendKaspa(
        toAddress: string,
        sompi: number,
        options?: { priorityFee?: number }
      ): Promise<string>
      signKRC20Transaction(
        inscribeJsonString: string,
        type: number,
        destAddr?: string,
        priorityFee?: number
      ): Promise<string>
      signMessage(
        msg: string,
        type?: "ecdsa" | "bip322-simple"
      ): Promise<string>
      on(
        event: "accountsChanged" | "networkChanged" | "balanceChanged",
        handler: (data: any) => void
      ): void
      removeListener(
        event: "accountsChanged" | "networkChanged" | "balanceChanged",
        handler: (data: any) => void
      ): void
    }
  }
}

const REFRESH_INTERVAL = 1 * 1e3

export const useKasware = () => {
  const [connected, setConnected] = useState(false)

  const connect = useCallback(async () => {
    if (!window.kasware) return

    try {
      await window.kasware.requestAccounts()
      setConnected(true)
    } catch (error) {
      console.error("Error connecting wallet:", error)
      setConnected(false)
    } finally {
      refreshData()
    }
  }, [])

  useRafInterval(() => {
    refreshData()
  }, REFRESH_INTERVAL)

  const [address, setAddress] = useState<string>("")
  const [publicKey, setPublicKey] = useState<string>("")
  const [balance, setBalance] = useState<{
    confirmed: number
    unconfirmed: number
    total: number
  } | null>(null)
  const [krc20Balances, setKrc20Balances] = useState<
    Array<KaswareKrc20Balance>
  >([])

  const refreshData = useCallback(async () => {
    if (!window.kasware) return

    try {
      // Get address
      const accounts = await window.kasware.getAccounts()
      if (accounts.length > 0) {
        setConnected(true)
        setAddress(accounts[0] || "")
      } else {
        setConnected(false)
      }

      // Get public key
      const pubKey = await window.kasware.getPublicKey()
      setPublicKey(pubKey)

      // Get balance
      const balanceData = await window.kasware.getBalance()
      setBalance(balanceData)

      // Get KRC20 balances
      const krc20Data = await window.kasware.getKRC20Balance()
      setKrc20Balances(krc20Data)
    } catch (error) {
      console.error("Error refreshing wallet data:", error)
    }
  }, [])

  useEffect(() => {
    refreshData() // TODO: balances are not refreshed when when new txns happened

    const handleBalanceRefresh = async () => {
      await refreshData()
    }

    window.kasware?.on("accountsChanged", handleBalanceRefresh)
    window.kasware?.on("balanceChanged", handleBalanceRefresh)
    return () => {
      window.kasware?.removeListener("accountsChanged", handleBalanceRefresh)
      window.kasware?.removeListener("balanceChanged", handleBalanceRefresh)
    }
  }, [refreshData])

  const isKaswareInstalled = typeof window.kasware !== "undefined"

  return {
    kasware: window.kasware,
    address,
    publicKey,
    balance,
    krc20Balances,
    refreshData,
    connectWallet: connect,
    connected,
    disconnectWallet: window.kasware?.disconnect,
    sendKaspa: window.kasware?.sendKaspa,
    signKRC20Transaction: window.kasware?.signKRC20Transaction,
    signMessage: window.kasware?.signMessage,
    isKaswareInstalled,
  }
}
