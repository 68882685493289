/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"
import { useIntl } from "react-intl"
import { GetTokenSummaryResponse } from "../../../../client"
import { Button, Popover, Skeleton, Space as AntdSpace } from "antd"
import { formatUnixTime, getTimeDifference } from "../../../utils/utils"
import { MintOnKSPRBotButton } from "../../../components/partials/MintOnKSPRBotButton"
import { TradeOnKSPRBotButton } from "../../../components/partials/TradeOnKSPRBotButton"
import { PremintPctBadge } from "../../../components/partials/PremintPctBadge"
import { getTokenStatus } from "../../../utils/tokenUtils"
import { DownOutlined } from "@ant-design/icons"
import { TokenSocials } from "./TokenSocials"
import { TradeOnChaingeButton } from "../../../components/partials/TradeOnChaingeButton"
import { getAddressRoute, getTransactionRoute } from "../../../hooks/navigator"
import { Link } from "react-router-dom"
import { BurnPctBadge } from "../../../components/partials/BurnPctBadge"

const Space = () => <div className="my-5"></div>

const labelClass = "fw-semibold text-muted text-break fs-6"
const valueClass =
  "fw-semibold text-dark text-break fs-6 d-flex align-items-center"

const renderCell = (label: string, value: React.ReactNode) => {
  return (
    <div className="d-flex flex-wrap">
      <div>
        <div className={`d-flex align-items-center mb-1`}>
          <span className={labelClass}>{label}</span>
        </div>
        {value}
      </div>
    </div>
  )
}

interface TokenHeaderSecondaryProps {
  token?: GetTokenSummaryResponse
  loading?: boolean
}

const TokenHeaderSecondary: React.FC<TokenHeaderSecondaryProps> = ({
  token,
  loading,
}) => {
  const intl = useIntl()
  const ticker = token?.ticker || ""
  const headerLoading = loading && !token

  return (
    <div className={`card mb-6`}>
      <div className="card-header border-0">
        <div className="card-title m-0">
          <h3 className="fw-bold m-0 d-flex align-items-center">
            <span className="me-2">
              {intl.formatMessage({ id: "TOKEN.HEADER.ADDITIONAL_DETAILS" })}
            </span>
          </h3>
        </div>
      </div>

      <div className="card-body border-top p-9">
        {headerLoading ? (
          <Skeleton active />
        ) : (
          <>
            {renderCell(
              intl.formatMessage({ id: "TOKEN.HEADER.SUPPLY_STATUS" }),
              <div className={valueClass}>
                {token && (
                  <>
                    <span className="me-2">
                      <PremintPctBadge token={token} withTitle />
                    </span>
                    <span className="me-2">
                      <BurnPctBadge token={token} withTitle />
                    </span>
                  </>
                )}
              </div>
            )}

            <Space />
            {renderCell(
              intl.formatMessage({ id: "TOKEN.HEADER.STATUS" }),
              <div className={valueClass}>
                <span className="badge badge-light-success">
                  {getTokenStatus(intl, token?.status || "")}
                </span>
              </div>
            )}

            <Space />
            {renderCell(
              intl.formatMessage({ id: "TOKEN.HEADER.DEPLOYED_BY" }),
              <div className={valueClass}>
                <Link
                  to={getAddressRoute(token?.deployerAddress || "#")}
                  className="text-body text-hover-primary"
                >
                  {token?.deployerAddress}
                </Link>
              </div>
            )}

            <Space />
            {renderCell(
              intl.formatMessage({ id: "TOKEN.HEADER.DEPLOYED_AT" }),
              <div className={valueClass}>
                <Link
                  to={getTransactionRoute(token?.revealHash || "#")}
                  className="text-body text-hover-primary"
                >
                  {formatUnixTime(
                    token?.deployedAt ? token?.deployedAt / 1e3 : 0
                  )}
                </Link>

                <span className="badge badge-light ms-2">
                  {getTimeDifference(token?.deployedAt || 0, false)}
                </span>
              </div>
            )}

            <Space />
            {renderCell(
              intl.formatMessage({ id: "TOKEN.HEADER.HOLDER_COUNT" }),
              <div className={valueClass}>
                {token?.holderTotal?.toLocaleString()}
              </div>
            )}

            <Space />
            {renderCell(
              intl.formatMessage({ id: "TOKEN.HEADER.MINT_COUNT" }),
              <div className={valueClass}>
                {token?.mintTotal?.toLocaleString()}
              </div>
            )}

            <Space />
            {renderCell(
              intl.formatMessage({ id: "TOKEN.HEADER.TRANSFER_COUNT" }),
              <div className={valueClass}>
                {token?.transferTotal?.toLocaleString()}
              </div>
            )}

            <Space />
            {renderCell(
              intl.formatMessage({ id: "TOKEN.HEADER.ACTIONS" }),
              <div className="d-flex flex-column">
                {token?.status !== "finished" && (
                  <div className="mb-4">
                    <MintOnKSPRBotButton ticker={ticker} />
                  </div>
                )}
                <span className="mb-4">
                  <TradeOnKSPRBotButton ticker={ticker} />
                </span>
                <TradeOnChaingeButton ticker={ticker} />
              </div>
            )}

            <Space />
            {renderCell(
              intl.formatMessage({ id: "TOKEN.HEADER.SOCIAL_LINKS" }),
              <Popover
                overlayInnerStyle={{ padding: 1 }}
                autoAdjustOverflow={false}
                content={<TokenSocials socialLinks={token?.socialLinks} />}
                trigger="click"
                placement="bottomLeft"
                arrow={false}
              >
                <Button
                  loading={headerLoading}
                  disabled={!token?.socialLinks?.length}
                >
                  <AntdSpace>
                    {token?.socialLinks?.length
                      ? intl.formatMessage({
                          id: "TOKEN.HEADER.SOCIAL_LINKS.CLICK_TO_VIEW",
                        })
                      : intl.formatMessage({ id: "NOT_AVAILABLE" })}
                    <DownOutlined />
                  </AntdSpace>
                </Button>
              </Popover>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export { TokenHeaderSecondary }
