import { useLocalStorageState } from "ahooks"
import { bookmarkedTokensKey } from "../../constants/localStorageKeys"

export const BookmarkTokenIcon = ({ ticker }: { ticker: string }) => {
  const [bookmarkedTokens, setBookmarkedTokens] = useLocalStorageState<
    string[]
  >(bookmarkedTokensKey, {
    defaultValue: [],
    listenStorageChange: true,
  })

  const isActive = bookmarkedTokens?.includes(ticker)
  const className = isActive ? "bi-star-fill text-primary" : "bi-star"

  return (
    <i
      className={`bi text-hover-primary ${className} fs-6 ms-1`}
      role="button"
      onClick={() => {
        if (!bookmarkedTokens || bookmarkedTokens.length === 0) {
          setBookmarkedTokens([ticker])
        } else if (!isActive) {
          setBookmarkedTokens([...bookmarkedTokens, ticker])
        } else {
          setBookmarkedTokens(bookmarkedTokens.filter((t) => t !== ticker))
        }
      }}
    ></i>
  )
}
