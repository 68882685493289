import { useLocalStorageState } from "ahooks"
import { watchlistEnabledKey } from "../../constants/localStorageKeys"
import { Tooltip } from "antd"
import { useIntl } from "react-intl"

export function BookmarkTokenEnableButton() {
  const intl = useIntl()
  const [watchlistEnabled, setWatchlistEnabled] = useLocalStorageState<boolean>(
    watchlistEnabledKey,
    {
      defaultValue: false,
      listenStorageChange: true,
    }
  )

  const isActive = watchlistEnabled
  const className = isActive
    ? "bi-star-fill text-primary"
    : "bi-star text-hover-primary"

  return (
    <Tooltip title={intl.formatMessage({ id: "TOGGLE_WATCHLIST" })}>
      <i
        className={`bi ${className} fs-3`}
        role="button"
        onClick={() => {
          if (!watchlistEnabled) {
            setWatchlistEnabled(true)
          } else {
            setWatchlistEnabled(false)
          }
        }}
      ></i>
    </Tooltip>
  )
}
