import BigNumber from "bignumber.js"
import {
  GetSwapQuoteResponse,
  GetTokenSummaryResponse,
} from "../../../../client"
import { useState } from "react"
import { formatUnits } from "ethers"
import { useIntl } from "react-intl"
import { Link } from "react-router-dom"
import { trackEvent } from "../../../utils/utils"

interface SwapDetailsProps {
  quoteResponse?: GetSwapQuoteResponse
  token?: GetTokenSummaryResponse
  fromDecimal: number
  toDecimal: number
  isBuyMode: boolean
  slippage: number
}

const SwapDetails: React.FC<SwapDetailsProps> = ({
  quoteResponse,
  token,
  fromDecimal,
  toDecimal,
  isBuyMode,
  slippage,
}) => {
  const intl = useIntl()
  const [isExpanded, setIsExpanded] = useState(false)
  const { quote, paymentInstruction } = quoteResponse || {}
  const fromAmountWhole = formatUnits(
    paymentInstruction?.sendAmount || 0,
    fromDecimal
  )
  const toAmountWhole = formatUnits(quote?.outAmount || 0, toDecimal)
  const swapRate = BigNumber(fromAmountWhole).div(BigNumber(toAmountWhole))
  const swapRateInKas = isBuyMode ? swapRate : BigNumber(1).div(swapRate)

  return (
    <div className="d-flex flex-column gap-1 text-muted fs-6">
      {token?.chaingeLpRate ? (
        <Link
          to="https://krc20.chainge.finance/"
          target="_blank"
          onClick={() => {
            trackEvent("clicked-earn-apr-ads", {
              token: token?.ticker,
            })
          }}
        >
          <span className="fw-semibold text-primary">
            {intl.formatMessage(
              { id: "EARN_APR_BY_PROVIDING_LIQUIDITY" },
              {
                token: token?.ticker,
                rate: token.chaingeLpRate,
              }
            )}
          </span>
        </Link>
      ) : null}

      <div
        className="d-flex align-items-center justify-content-between cursor-pointer"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <div className="fw-semibold">
          1 {token?.ticker} ={" "}
          <span className="text-body mx-1">
            {swapRateInKas.gt(0)
              ? Number(swapRateInKas).toLocaleString(undefined, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: token?.decimal, // Sometimes chainDecimal is different from token decimal, in that case token decimal is used to make it shorter
                })
              : "0"}
          </span>
          KAS
        </div>
        <i className={`bi bi-chevron-${isExpanded ? "up" : "down"}`}></i>
      </div>

      {isExpanded && (
        <div className="d-flex flex-column gap-1 mt-2">
          <div className="fw-semibold d-flex justify-content-between">
            <div>
              <i className="bi bi-cash-coin me-2 fw-semibold"></i>
              {intl.formatMessage({ id: "SERVICE_FEE" })}
            </div>
            <div>
              <i className="bi bi-fire me-1 text-danger"></i>
              <span className="me-1 text-danger">
                {Number(quote?.serviceFeeRate || 0) / 100}%
              </span>
              <span className="text-decoration-line-through text-muted">
                3%
              </span>
            </div>
          </div>

          <div className="fw-semibold d-flex justify-content-between">
            <div>
              <i className="bi bi-arrow-left-right me-2 fw-semibold"></i>
              {intl.formatMessage({ id: "ORDER_ROUTING" })}
            </div>
            <span className="text-body">Chainge</span>
          </div>

          <div className="fw-semibold d-flex justify-content-between">
            <div>
              <i className="bi bi-graph-up me-2 fw-semibold"></i>
              {intl.formatMessage({ id: "PRICE_IMPACT" })}
            </div>
            <span className="text-body">
              {Number(quote?.priceImpact || 0)}%
            </span>
          </div>

          <div className="fw-semibold d-flex justify-content-between text-muted">
            <div>
              <i className="bi bi-sliders me-2 fw-semibold"></i>
              {intl.formatMessage({ id: "MAX_SLIPPAGE" })}
            </div>
            <span className="text-body">
              {slippage || Number(quote?.slippage || 0)}%
            </span>
          </div>
        </div>
      )}
    </div>
  )
}

export { SwapDetails }
